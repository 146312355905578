import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "drive-scroll" }
const _hoisted_5 = {
  key: 0,
  class: "col-3 mb-4 elem"
}
const _hoisted_6 = { class: "card h-100" }
const _hoisted_7 = { class: "card-body d-flex justify-content-center text-center flex-column p-1" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "symbol symbol-100px m-4" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 0,
  class: "fs-5 fw-bolder"
}
const _hoisted_12 = {
  key: 1,
  class: "fs-5 fw-bolder"
}
const _hoisted_13 = {
  href: "",
  class: "text-gray-800 text-hover-primary d-flex flex-column"
}
const _hoisted_14 = { class: "symbol symbol-100px m-4" }
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 0,
  class: "fs-5 fw-bolder"
}
const _hoisted_17 = {
  key: 1,
  class: "fs-5 fw-bolder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userDocData, (category) => {
    return (_openBlock(), _createElementBlock("div", {
      key: category.id,
      class: "row mt-5"
    }, [
      (category.documents.length !== 0)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_1, [
            (_ctx.i18n && _ctx.i18n.locale === 'fr')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(category.label), 1))
              : _createCommentVNode("", true),
            (_ctx.i18n && _ctx.i18n.locale === 'en')
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(category.label_en), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.documents, (document) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: document.id
          }, [
            (document.user_types.includes(this.$store.getters.currentUser.user.userprofile.user_type.id))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      (document.filetype !== 'video')
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: "#",
                            onClick: ($event: any) => (_ctx.handleDocClick(document.id)),
                            class: "text-gray-800 text-hover-primary d-flex flex-column"
                          }, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("img", {
                                src: document.picto,
                                alt: ""
                              }, null, 8, _hoisted_10)
                            ]),
                            (_ctx.i18n && _ctx.i18n.locale === 'fr')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(document.label), 1))
                              : _createCommentVNode("", true),
                            (_ctx.i18n && _ctx.i18n.locale === 'en')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(document.label_en), 1))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_8))
                        : (_openBlock(), _createBlock(_component_router_link, {
                            key: 1,
                            to: '/drive/' + document.id + '/'
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("a", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, [
                                  _createElementVNode("img", {
                                    src: document.picto,
                                    alt: ""
                                  }, null, 8, _hoisted_15)
                                ]),
                                (_ctx.i18n && _ctx.i18n.locale === 'fr')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(document.label), 1))
                                  : _createCommentVNode("", true),
                                (_ctx.i18n && _ctx.i18n.locale === 'en')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(document.label_en), 1))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ]))
  }), 128))
}